import React from 'react';
import { Route, Switch } from 'react-router-dom'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import BurgerMenu from './components/Menu/Menu'
import Home from './components/Home/Home'
import Contact from './components/Contact/Contact'
import About from './components/About/About'
import Services from './components/Services/Services'
import Products from './components/Products/Products'
import Markets from './components/Markets/Markets'
import Support from './components/Support/Support'
import NotFound from './components/NotFound/NotFound'

function App() {
  return (
    <main className='App'>
      <BurgerMenu />
      <Header />
      <Switch>
      <Route
              exact path={'/'}
              component={Home}
            />
       <Route
              path={'/contact'}
              component={Contact}
            />
        <Route
              path={'/about'}
              component={About}
            />
        <Route
              path={'/services'}
              component={Services}
            />
        <Route
              path={'/products'}
              component={Products}
            />
        <Route
              path={'/markets'}
              component={Markets}
            />
        <Route
              path={'/support'}
              component={Support}
            />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </main>
  );
}

export default App;

import React, { Component } from 'react';
import Slider from '../Slider/Slider'
import ProductSlider from '../ProductSlider/ProductSlider'
import Payment from '../Payment/Payment'
import Social from '../Social/Social'
import Brands from '../Brands/Brands'
import './Home.css' 

class Home extends Component {

 
  render () {
    return (
    <div className="HOME_Container">
      <Slider />
      <ProductSlider />
      <Payment />
      <div className="Home_flex">
      <Social /><Brands />
      </div>
    </div>
    )
  }
}

export default Home;
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './NavBar.css'

class NavBar extends Component {

 
  render () {
    return (
    <div className="NavBar_Container">
        <ul className="Nav_ul">
            <li><Link to='/' id="home" className="menu-item">Home</Link></li>
            <li><Link id="about" className="menu-item" to="/about">About</Link></li>
            <li><Link id="products" className="menu-item" to="/products">Products</Link></li>
            <li><Link id="services" className="menu-item" to="/services">Services</Link></li>
            <li><Link id="markets" className="menu-item" to="/markets">Markets</Link></li>
            <li> <Link id="support" className="menu-item" to="/support">Support</Link></li>
            <li><Link id="contact" className="menu-item" to="/contact">Contact</Link></li>
        </ul>
    </div>
    )
  }
}

export default NavBar;
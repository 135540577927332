import React, { Component } from 'react';
import './Markets.css'

class Markets extends Component {
   
 
  render () {
    return (
    <div className="Markets_Container">
<div className="Markets_Title">
            <h2 className="Markets_titles">Markets</h2>
        </div>

    </div>
    )
  }
}

export default Markets;
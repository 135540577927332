import React, { Component } from 'react';
import './Products.css'
import HERCULES from '../../images/Hercules_logo.png'
import PARKER from '../../images/ParkerLogo.png'
import HOERBIGER from '../../images/HOERBIGER_logo.png'
import ProductSlider from '../ProductSlider/ProductSlider'

class Products extends Component {
   
 
  render () {
    return (
    <div className="Products_Container">
        <div className="Products_Title">
            <h2 className="Products_titles">Products</h2>
          </div>
        <div className="Products_custom">
        <h2 className="Products_titles">We create custom fluid power solutions. If It's Fluid Power, We Have It!</h2>
          <div className="Products_custom_flex">
          <ul className="Product_list"><li>Cylinders</li><li>Directional Valves</li><li>Actuators</li><li>Pumps</li><li>Motors</li></ul><ul className="Product_list"><li>Tubing</li><li>Hose</li><li>Fittings</li><li>Filter</li><li>Regulators & Lubricators</li></ul><ul className="Product_list"><li>Flow Meters</li><li>Pressure Gauges</li><li>Line Accessories</li><li>Hydraulic Power Units</li></ul>
          </div></div>
        
        <div className="Distribution_brands">
            <h2 className="Products_titles">In addition to building custom pieces, we proudly work with some of the top brands in the business.</h2>
            <div className="Distribution_flex"><img src={HOERBIGER} alt="Hoerbiger" className="Brand_icon"/>
              <img src={PARKER} alt="Parker" className="Brand_icon"/>
              <img src={HERCULES} alt="Hercules" className="Brand_icon"/>
            </div>
          </div>
    </div>
    )
  }
}

export default Products;
import React, { Component } from 'react';
import './Contact.css'


class Contact extends Component {
   
 
  render () {
    return (
    <div className="Contact_Container">

        <div className="Contact_Title">
            <h2 className="Contact_titles">CONTACT</h2>
        </div>
        <div className="Info_wrapper">
        <div className="Contact_section">
            <h2 className="Contact_titles">PHONE</h2>
            <div className="Phone_content">
                <div className="Phone_item"><a href="tel:1-706-884-3311">
                    (706) 884-3311</a> In LaGrange</div>
                <div className="Phone_item"><a href="tel:1-404-577-2664">
                    (404) 577-2664</a> In Atlanta</div>
                <div className="Phone_item"><a href="tel:1-706-885-9107">
                    (706) 885-9107</a> After Hours</div>
                    <div className="Phone_item"><a href="tel:1-706-594-7914">
                    (706) 594-7914</a> George Daniel</div>
                    <div className="Phone_item"><a href="tel:1-706-881-3147">
                    (706) 881-3147</a> Cory Gouchenour</div>
            </div>
            </div>
            <div className="Contact_section">
            <h2 className="Contact_titles">FAX</h2>
            <div className="Phone_content">
                <div className="Phone_item">(706) 884-9825</div>
            </div>
            </div>
            <div className="Contact_section">
            <h2 className="Contact_titles">EMAIL</h2>
            <div className="Phone_content"><a href="mailto:sales@fluidpowerproducts.com" target="_blank" rel="noopener noreferrer">
                sales@fluidpowerproducts.com</a></div>
           </div>
            </div>

        <div className="Map_wrapper">
            <h2 className="Contact_titles">VISIT US</h2>
            <div className="Map_Info_wrapper">
            <iframe  title="Equipment Sales Map"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3345.5870785471525!2d-85.06944888468331!3d33.014660280900976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x888b57b28f4dc567%3A0x2cacfcf702db7560!2s1655%20W%20Lukken%20Industrial%20Dr%2C%20LaGrange%2C%20GA%2030240!5e0!3m2!1sen!2sus!4v1574799309206!5m2!1sen!2sus" className="Map"/>
            <div className="Map_content"> We are located just south of Atlanta. <br/> Our 21,000 square foot facility is located in a major industrial park. We keep a large inventory on hand ready for immediate shipment or purchase on site.
            <br/>At Equipment Sales & Service Corporation you will find some of the most knowledgeable and helpful salespeople. We are happy to assist you for all your fluid power needs.</div>
            </div>
        </div>


    </div>
    )
  }
}

export default Contact;
import React, { Component } from "react";
import Slider from "react-slick";
import ESSVALVE from '../../images/ESS_valve.jpg'
import ESSPUMP from '../../images/ESS_pump.jpg'
import ESSHOSE from '../../images/ESS_hose.jpg'
import ESSTUBE from '../../images/ESS_tube.jpg'
import ESSREGULATOR from '../../images/ESS_regulator.jpg'
import ESSCYLINDER from '../../images/ESS_cylinder.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ProductSlider.css'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#4287f5", color: "white" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#4287f5", color: "white" }}
      onClick={onClick}
    />
  );
}


export default class ProductSlider extends Component {
    render() {
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        adaptiveHeight: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        
        };
    
      return (
        <div className="Product_slider_container">
        <h2 className="Product_slider_title">FEATURED PRODUCTS</h2>
          <Slider {...settings}>
            <div className="Product_item_container">
              <h3 className="Product_title">HOSES</h3>
              <img src={ESSHOSE} className="Product_img" alt="Hoses"/>
            </div>
            <div className="Product_item_container">
              <h3 className="Product_title">TUBING</h3>
              <img src={ESSTUBE} className="Product_img" alt="Tubes"/>
            </div>
            <div className="Product_item_container">
              <h3 className="Product_title">REGULATORS</h3>
              <img src={ESSREGULATOR} className="Product_img" alt="Regulators"/>
            </div>
            <div className="Product_item_container">
              <h3 className="Product_title">VALVES</h3>
              <img src={ESSVALVE} className="Product_img" alt="Valves"/>
            </div>
            <div className="Product_item_container">
              <h3 className="Product_title">PUMPS</h3>
              <img src={ESSPUMP} className="Product_img" alt="Pumps"/>
            </div>
            <div className="Product_item_container">
              <h3 className="Product_title">CYLINDERS</h3>
              <img src={ESSCYLINDER} className="Product_img" alt="Cylinders"/>
            </div>
          </Slider>
        </div>
      );
    }
  }
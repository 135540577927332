import React, { Component } from 'react';
import './NotFound.css'

class NotFound extends Component {
   
 
  render () {
    return (
    <div className="NotFound_Container">

<p>We are sorry, the page you are looking for cannot be located.</p>
    </div>
    )
  }
}

export default NotFound;
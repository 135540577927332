import React, { Component } from 'react';
import HERCULES from '../../images/Hercules_logo.png'
import PARKER from '../../images/ParkerLogo.png'
import HOERBIGER from '../../images/HOERBIGER_logo.png'
import './Brands.css'

class Brands extends Component {

 
  render () {
    return (
    <div className="Brands_Container">
    <h2 className="Brands_title">Brands</h2>
    <div className="Brands_types">
      <img src={HOERBIGER} alt="Hoerbiger" className="Brand_icon"/>
      <img src={PARKER} alt="Parker" className="Brand_icon"/>
      <img src={HERCULES} alt="Hercules" className="Brand_icon"/>
      </div>
    </div>
    )
  }
}

export default Brands;
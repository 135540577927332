import React, { Component } from 'react';
import './Payment.css'
import PAYPALLOGO from '../../images/paypal-logo.png'
import SQUARELOGO from '../../images/square-logo-transparent-4.png'
class Payment extends Component {

 
  render () {
    return (
    <div className="Payment_Container">
    <h2 className="Payment_title">PAYMENT</h2>
      <div className="Payment_types">
        <div className="Payment_content">We are happy to accept all major credit cards. Payments can be processed over the phone, in person or online.</div>
        <img src={PAYPALLOGO} className="Paypal_logo" alt="Paypal"/>
        <img src={SQUARELOGO} className="Square_logo" alt="Square"/>
      </div>
    </div>
    )
  }
}

export default Payment;
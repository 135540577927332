import React, { Component } from 'react';
import FBLOGO from '../../images/FBLOGO.png'
import './Social.css'

class Social extends Component {

 
  render () {
    return (
    <div className="Social_Container">
    <h2 className="Social_title">Social Media</h2>
      <div className="Payment_types">
        <a href="https://www.facebook.com/FluidPowerProducts1/" target="_blank" rel="noopener noreferrer"><img src={FBLOGO} className="FBLOGO" alt="Facebook"/></a>
      </div>
    </div>
    )
  }
}

export default Social;
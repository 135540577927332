import React, { Component } from 'react';
import ESSBUILDING from '../../images/ESS_building.png'
import './About.css'

class About extends Component {
   
 
  render () {
    return (
    <div className="About_Container">
    <div className="About_Title">
            <h2 className="About_titles">ABOUT</h2>
        </div>
<div className="About_Picture_Container">
<div className="About_Header_Container">
  <h2>Equipment Sales & Service Corporation was founded in 1976 in LaGrange, Georgia.</h2>
</div>
    <img src={ESSBUILDING} alt="Equipment Sales Building" className="Building_Pic"/>
</div>

    </div>
    )
  }
}

export default About;
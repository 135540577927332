import React, { Component } from 'react';
import './Services.css'

class Services extends Component {
   
 
  render () {
    return (
    <div className="Services_Container">
<div className="Services_Title">
            <h2 className="Services_titles">Services</h2>
        </div>

    </div>
    )
  }
}

export default Services;
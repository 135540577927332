import React, { Component } from 'react';
import './Support.css'

class Support extends Component {
   
 
  render () {
    return (
    <div className="Support_Container">
<div className="Support_Title">
            <h2 className="Support_titles">Support</h2>
        </div>

    </div>
    )
  }
}

export default Support;
import { slide as Menu } from 'react-burger-menu'
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './Menu.css'

class BurgerMenu extends Component {
  showSettings (event) {
    event.preventDefault();
  }
 
  render () {
    return (
      <Menu right disableAutoFocus>
        <Link to='/' id="home" className="menu-item">Home</Link>
        <Link id="about" className="menu-item" to="/about">About</Link>
        <Link id="products" className="menu-item" to="/products">Products</Link>
        <Link id="services" className="menu-item" to="/services">Services</Link>
        <Link id="markets" className="menu-item" to="/markets">Markets</Link>
        <Link id="support" className="menu-item" to="/support">Support</Link>
        <Link id="contact" className="menu-item" to="/contact">Contact</Link>
      </Menu>
    );
  }
}

export default BurgerMenu;
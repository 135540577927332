import React, { Component } from 'react';
import ESSCARD from '../../images/ESScard.gif'
import './Footer.css'

class Footer extends Component {
    render(){
    return  <div className="Footer_wrapper">
    <div className="Footer_container">
    <div className="Footer_content">
           <div className="Address_container">
           Equipment Sales & Service Corporation<br/>
           1655 Lukken Industrial Drive West<br/>
           LaGrange, Georgia  30240   USA</div>
           <div className="Phone_container">
           Contact Us By Phone At:<br/>
           <a href="tel:1-404-577-2664">
           (404) 577-2664</a> In Atlanta<br/>
           <a href="tel:1-706-884-3311">
           (706) 884-3311</a> In LaGrange</div>
           <div className="Fax_container">
           Email: <br/>
           <a href="mailto:sales@fluidpowerproducts.com" target="_blank" rel="noopener noreferrer">
           sales@fluidpowerproducts.com</a><br/>
           Fax:<br/> (706) 884-9825</div>
        </div>
        <div className="Footer_content">
        <div className="Slogan_container">
        Distributing Quality Fluid<br/> Power Products Worldwide<br/> Since 1976
                </div>
           <div className="Copyright_container">
            © EQUIPMENT SALES & 
            SERVICE 2019</div>
            <div className="Card_container">
                <img src={ESSCARD} className="ESSCARD" alt="Equipment Sales and Service Corp"/>
                </div>
        </div>



    </div>
     </div>
}
}

export default Footer;